<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="4"
                sm="3"
              >
                <v-text-field
                  dense
                  disabled
                  persistent-placeholder
                  filled
                  v-model="item.purchaseId"
                  label="Nº pedido"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="3"
              >
                <v-text-field
                  dense
                  disabled
                  filled
                  height="26px"
                  type="date"
                  v-model="item.orderDate"
                  label="Fecha pedido"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  dense
                  :readonly="!editMode"
                  auto-select-first
                  persistent-placeholder
                  filled
                  ref="focussedInput"
                  v-model="item.supplierId"
                  :rules="supplierIdRules"
                  :items="suppliersSelect"
                  item-text="supplierName"
                  item-value="supplierId"
                  label="Proveedor *"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row
              dense
            >
              <v-col
                cols="4"
                sm="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="number"
                  :rules="receptNoRules"
                  v-model="item.receptNo"
                  label="Nº entrada"
                  :hint="(!item.receptNo && editMode) ? 'Haz clic en el icono para asignar el próximo Nº entrada' : undefined"
                  :append-icon="(!item.receptNo && editMode) ? mdiCounter : undefined"
                  @click:append="setReceptNo"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  filled
                  height="26px"
                  type="date"
                  :rules="receptDateRules"
                  v-model="item.receptDate"
                  label="Fecha entrada *"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.currency"
                  label="Moneda *"
                  :items="currenciesSelect"
                  item-text="key"
                  item-value="key"
                >
                </v-select>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  :disabled="item.currency == defaultCurrency || !editMode"
                  persistent-placeholder
                  filled
                  type="number"
                  :rules="exchangeRateRules"
                  v-model="item.exchangeRate"
                  label="Cambio *"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="number"
                  :rules="additionalCostsRules"
                  v-model="item.additionalCosts"
                  label="Gastos *"
                ></v-text-field>
              </v-col>
            </v-row>

        <v-row
          dense
        >
          <v-col
            mt
            cols="12"
            sm="12"
            class="my-4"
          >
            <PurchaseProductsTable
              v-if="item.purchaseItems"
              v-model="item.purchaseItems"
              :purchaseId="item.purchaseId"
              :editMode="editMode"
            >
            </PurchaseProductsTable>
          </v-col>
        </v-row>

            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.orderComments"
                  label="Instrucciones pedido"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.receptComments"
                  rows="2"
                  label="Comentarios (internos)"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-container>
          <!-- footer -->
          <div
            class="text-caption"
          >
            <span v-if="item.purchaseId === 'new_'">Pendiente de guardar.</span>
            <span v-else>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!editMode && item.supplierId === 0"
            text
            link
            target="_blank"
            :href="apiHost + '/api/pdfdocs/repacking_' + ('0000' + item.purchaseId).substr(-4) + '.pdf'"
          >
            <v-icon left>
              {{ mdiFilePdfBox }}
            </v-icon>
            Repacking/Corrección
          </v-btn>
          <v-btn
            v-if="!editMode && item.supplierId !== 0"
            text
            link
            target="_blank"
            :href="apiHost + '/api/pdfdocs/purchaseorder_' + ('0000' + item.purchaseId).substr(-4) + '.pdf'"
          >
            <v-icon left>
              {{ mdiFilePdfBox }}
            </v-icon>
            Pedido
          </v-btn>
          <v-btn
            v-if="!editMode && item.receptNo && item.supplierId !== 0"
            text
            link
            target="_blank"
            :href="apiHost + '/api/pdfdocs/goodsentry_' + ('0000' + item.purchaseId).substr(-4) + '.pdf'"
          >
            <v-icon left>
              {{ mdiFilePdfBox }}
            </v-icon>
            Entrada
          </v-btn>
          <v-btn
            v-if="editMode && item.purchaseId !== 'new_' && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode && item.purchaseId !== 'new_' && userCanEdit"
            @click="copyItem"
            text
            title="Copiar y usar como modelo para nuevo elemento"
          >
            Copiar
          </v-btn>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            title="Volver a lista (Esc)"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import PurchaseProductsTable from '@/components/PurchaseProductsTable'
import { mdiFilePdfBox, mdiCounter } from '@mdi/js'
import ItemMixins from '@/utils/ItemMixins'
import api from '../api'

export default {
  mixins: [ItemMixins],
  components: {
    PurchaseProductsTable
  },
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemName: 'purchase', // needed for ItemMixins
      itemsName: 'purchases', // needed for ItemMixins
      apiHost: this.$store.state.apiHost,
      mdiFilePdfBox: mdiFilePdfBox,
      mdiCounter: mdiCounter,
      supplierIdRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Falta elegir un proveedor'
      ],
      receptNoRules: [
        // null, blank or positive integer
        (v) => (v === null || v === '' || /^[1-9][0-9]*$/.test(v)) || 'Debe ser blanco o un número'
      ],
      receptDateRules: [
        (v) => !!v || '¡Falta especificar una fecha de recepción (deseada o real)'
      ],
      exchangeRateRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ],
      additionalCostsRules: [
        // positive decimal
        (v) => /^\d*\.{0,2}\d+$/.test(v) || 'Debe ser 0 o un número positivo'
      ],
      defaultCurrency: this.$store.state.defaultCurrency,
      currenciesSelect: this.$store.state.currenciesSelect
    }
  },
  computed: {
    suppliersSelect () {
      return this.$store.state.suppliersSelect
    },
    totalAmount () {
      let amount = 0
      for (const el of this.item.purchaseItems) {
        amount += el.qty * el.price
      }
      return amount
    }
  },
  watch: {
    purchase: {
      handler (val) {
        if (val.currency === this.defaultCurrency && this.item.exchangeRate !== 1) {
          this.item.exchangeRate = 1
        }
      },
      deep: true
    }
  },
  methods: {
    copyItem () {
      this.createItem(this.item)
    },
    createItem (copyFrom) {
      if (copyFrom) {
        // create item for each product in copyFrom
        const lookup = {}
        for (const item of copyFrom.purchaseItems) {
          if (!lookup[item.productId]) {
            lookup[item.productId] = {
              price: item.price
            }
          }
        }

        this.item = {
          purchaseId: 'new_',
          additionalCosts: 0,
          currency: copyFrom.currency,
          exchangeRate: copyFrom.exchangeRate,
          orderComments: copyFrom.orderComments,
          orderDate: new Date().toISOString().slice(0, -14),
          receptComments: '',
          receptDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, -14),
          receptNo: '',
          supplierId: copyFrom.supplierId,
          purchaseItems: []
        }

        for (const key in lookup) {
          this.item.purchaseItems.push({
            // random id
            purchaseItemId: Math.round((Math.random() * 1000000000)),
            productId: key,
            palletId: '-',
            qty: 0, // for some reason can't set summed qty here
            price: lookup[key].price
          })
        }

        // replace path without rendering
        window.history.replaceState({}, null, '/#/' + this.itemsName + '/new_')
        // set focus
        if (this.$refs.focussedInput) {
          this.$refs.focussedInput.focus()
        }
      } else {
        this.item = {
          purchaseId: 'new_',
          additionalCosts: 0,
          currency: this.$store.state.defaultCurrency,
          exchangeRate: 1,
          orderComments: '',
          orderDate: new Date().toISOString().slice(0, -14),
          receptComments: '',
          receptDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, -14),
          receptNo: '',
          supplierId: '',
          purchaseItems: [{
            // random id
            purchaseItemId: Math.round((Math.random() * 1000000000)),
            productId: '',
            palletId: '-',
            qty: 10,
            price: 0
          }]
        }
      }
      this.editMode = true
    },
    setReceptNo () {
      api.getPurchasesNextReceptNo()
        .then(data => {
          this.item.receptNo = data ? data.nextReceptNo : ''
        })
    }
  }
}
</script>
