<template>
  <v-data-table
    style="white-space: nowrap;"
    hide-default-footer
    :headers="headers"
    :items="purchaseItems"
    :options="{itemsPerPage: -1}"
    :class="editMode ? 'elevation-0' : 'hideFirstColumn elevation-0'"
    mobile-breakpoint="0"
  >

    <template
      v-slot:item="{ item, index }"
    >

      <PurchaseProductsTableItem
        v-model="purchaseItems[index]"
        :key="item.purchaseItemId"
        :purchaseId="item.purchaseId"
        :editMode="editMode"
        :purchaseItemsCount="purchaseItems.length"
        @deleteRow="deleteRow"
      >
      </PurchaseProductsTableItem>

    </template>
    <template
      v-slot:[`body.append`]="{}"
    >
      <tr
        class="trNoHighlight"
      >
        <td
          v-if="editMode"
        >
          <v-btn
            @click="addRow()"
            title="Añadir producto (Ctrl. + Ins.)"
            x-small
            fab
            elevation="2"
            color="primary"
          >
            <v-icon>{{ mdiPlus }}</v-icon>
          </v-btn>
        </td>
        <td
          colspan="3"
        >
          Peso: {{ Math.ceil(totalWeight) }} Kg
        </td>
        <td
          colspan="2"
          class="text-right"
        >
          {{ totalAmount | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mdiDelete, mdiPlus } from '@mdi/js'
import PurchaseProductsTableItem from '@/components/PurchaseProductsTableItem'
import EventBus from '@/components/EventBus'

export default {
  components: {
    PurchaseProductsTableItem
  },
  data () {
    return {
      mdiDelete: mdiDelete,
      mdiPlus: mdiPlus,
      valid: false,
      productIdRules: [
        (v) => !!v || 'Falta elegir un producto'
      ],
      palletIdRules: [
        (v) => !!v || 'No puede estar en blanco'
      ],
      qtyRules: [
        // positiv or negativ integer
        (v) => /^-?[0-9]+$/.test(v) || 'Debe ser un número entero'
      ],
      priceRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ],

      headers: [
        {
          text: '',
          value: false,
          sortable: false,
          class: 'firstColumn'
        },
        {
          text: 'Producto',
          value: 'productId',
          sortable: false
        },
        {
          text: 'Palet Id.',
          value: 'palletId',
          sortable: false
        },
        {
          text: 'Cantidad',
          align: 'right',
          value: 'qty',
          sortable: false
        },
        {
          text: 'Precio',
          align: 'right',
          value: 'price',
          sortable: false
        },
        {
          text: 'Importe',
          align: 'right',
          // value: 'price',
          sortable: false
        }
      ]
    }
  },
  props: {
    // value represents purchaseItems passed as model
    value: Array,
    purchaseId: [String, Number],
    editMode: Boolean
  },
  computed: {
    purchaseItems: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    defaultCurrencySymbol () {
      return this.$store.state.defaultCurrencySymbol
    },
    totalWeight () {
      let weight = 0
      for (const el of this.purchaseItems) {
        if (el.weight) {
          weight += el.qty * el.weight
        }
      }
      return weight
    },
    totalAmount () {
      let amount = 0
      for (const el of this.purchaseItems) {
        amount += el.qty * el.price
      }
      return amount
    }
  },
  /*
  watch: {
    value: {
      handler: function (purchaseItems) {
        this.purchaseItems = purchaseItems
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }

  }, */
  mounted () {
    EventBus.$on('ctrlKey_Ins', this.addRow)
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_Ins', this.addRow)
  },
  methods: {
    addRow () {
      if (!this.editMode) {
        return
      }
      const newRow = {
        // generate random temporal integer id
        purchaseItemId: Math.round((Math.random() * 1000000000)),
        productId: '',
        palletId: '-',
        qty: 0,
        price: 0
      }
      if (this.purchaseId !== 'new_') {
        newRow.purchaseId = this.purchaseId
      }
      this.purchaseItems.push(newRow)
    },
    deleteRow (purchaseItemId) {
      this.purchaseItems = this.purchaseItems.filter((el) => {
        return el.purchaseItemId !== purchaseItemId
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

  .hideFirstColumn .firstColumn {
    display: none !important;
  }

</style>
