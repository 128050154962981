<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      class="overflowLayout ma-0"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          class="purchasesTable"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          height="calc(100vh - 130px)"
          mobile-breakpoint="0"
          :value="selected"
          single-select
        >
          <template
            v-slot:[`body.prepend`]="{ headers }"
          >
            <tr
              class="tableFilter"
              py-3
            >
              <td v-for="(header,key) in headers" :key="key" class="pb-1">
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template
            v-slot:item="{ item, index }"
          >
            <tr
              :key="item.purchaseId"
              @click.stop="showItem(item)"
              :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
            >
              <td class="text-right">{{ item.purchaseId }}</td>
              <td> {{ item.supplierName }} </td>
              <td>{{ (item.orderDate || '').substr(0,10) }}</td>
              <td class="text-right">{{ item.receptNo }}</td>
              <td>{{ (item.receptDate || '').substr(0,10) }}</td>
              <td class="purchaseProds"> {{ item.purchaseProds }} </td>
              <td class="text-right">{{ item.weight | toRounded(0) }}</td>
              <td class="text-right">{{ item.purchases | toRounded(2) }}</td>
              <td class="text-right">{{ item.additionalCosts | toRounded(2) }}</td>
            </tr>
          </template>

          <template v-slot:[`footer.prepend`]>
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear pedido (Ctrl. + Ins.)"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemsName: 'purchases',
      itemName: 'purchase',
      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'purchaseId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Proveedor',
          value: 'supplierName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Fecha',
          value: 'orderDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Entrada',
          value: 'receptNo',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Recepción',
          value: 'receptDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Productos',
          value: 'purchaseProds',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Peso',
          value: 'weight',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Compras',
          value: 'purchases',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Gastos',
          value: 'additionalCosts',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        }
      ]
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .purchasesTable {
    white-space: nowrap;
  }
  .purchasesTable .purchaseProds {
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
