<template>
  <tr
  >
  <td
    v-if="editMode"
  >
    <v-btn
      :disabled="purchaseItemsCount === 1"
      @click="$emit('deleteRow', item.purchaseItemId)"
      title="Borrar producto"
      x-small
      fab
      class="elevation-0"

    >
      <v-icon
        color="error"
      >
        {{ mdiDelete }}
      </v-icon>
    </v-btn>
  </td>

    <td
      class="hideInputMsg pt-1"
    >
      <v-autocomplete
        ref="productId"
        dense
        :readonly="!editMode"
        solo
        flat
        auto-select-first
        :rules="productIdRules"
        v-model="item.productId"
        :items="productsSelect"
        item-text="productId"
        item-value="productId"
        :filter="filterProduct"
        :title="item.productName"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.productId"></v-list-item-title>
            <v-list-item-subtitle v-text="item.productName"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </td>
    <td
      class="hideInputMsg pt-1"
    >
      <v-text-field
        dense
        :readonly="!editMode"
        solo
        flat
        type="text"
        height="35px"
        :rules="palletIdRules"
        v-model="item.palletId"
      ></v-text-field>
    </td>
    <td
      class="hideInputMsg pt-1"
    >
      <v-text-field
        dense
        :readonly="!editMode"
        solo
        flat
        reverse
        type="number"
        :rules="(item.showWeight) ? qtyRulesKg : qtyRules"
        :prefix="(item.showWeight) ? 'Kg' : 'Uds'"
        v-model="qty"
        :title="productPalletSizeText"
        @change="changeQty($event)"
      ></v-text-field>
    </td>
    <td
      class="hideInputMsg pt-1"
    >
      <v-text-field
        dense
        :readonly="!editMode"
        solo
        flat
        reverse
        type="number"
        :rules="priceRules"
        v-model="price"
      ></v-text-field>
    </td>
    <td
      class="text-right">{{ item.price * item.qty | toRounded(2) }} {{ ' ' + defaultCurrencySymbol }}
    </td>
  </tr>
</template>

<script>
import { mdiDelete } from '@mdi/js'
// import Vue from 'vue' // does not work with this here !?

export default {
  data () {
    return {
      mdiDelete: mdiDelete,
      productPalletsSelect: [],
      productIdRules: [
        (v) => !!v || 'Falta elegir un producto'
      ],
      palletIdRules: [
        (v) => !!v || 'No puede estar en blanco'
      ],
      qtyRulesKg: [
        // positive or negativ integer or decimal with up to 4 digits
        (v) => /^-?\d*\.{0,4}\d+$/.test(v) || 'Debe ser un número.'
      ],
      qtyRules: [
        // positiv or negativ integer
        (v) => /^-?[0-9]+$/.test(v) || 'Debe ser un número entero'
      ],
      priceRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ]
    }
  },
  props: {
    // value represents item passed as model
    value: Object,
    editMode: Boolean,
    purchaseItemsCount: Number,
    purchaseId: Number
  },
  mounted () {
    // focus on new item
    if (!this.item.productId) {
      this.$refs.productId.focus()
    }
  },
  computed: {
    item: {
      get () {
        return this.value || {}
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    defaultCurrencySymbol () {
      return this.$store.state.defaultCurrencySymbol
    },
    productsSelect () {
      return this.$store.state.productsSelect
    },

    showPalletId () {
      return (['PI', 'AL', 'FA']).includes(this.saleStatus)
    },
    // base on price as number
    price: {
      get () {
        if (!this.item) {
          return 0
        }
        // avoid rounding errors from multiplication
        return (this.item.showWeight) ? Math.round(100 * this.item.price / this.item.weight) / 100 : this.item.price
      },
      set (value) {
        // avoid that any 3rd digit enters into calculation
        const val = (this.item.showWeight) ? (this.item.weight * Math.round(100 * +value) / 100) : (Math.round(100 * +value) / 100)
        this.$set(this.item, 'price', val)
      }
    },

    qty: {
      get () {
        if (!this.item) {
          return 0
        }
        return (this.item.showWeight) ? (this.item.qty * this.item.weight) : this.item.qty
      },
      set (value) {
        const val = (this.item.showWeight) ? (+value / this.item.weight) : +value
        this.$set(this.item, 'qty', val)
      }
    },
    productPalletSizeText () {
      // don't show if empty productId
      if (!this.item.productId) {
        return ''
      }
      return 'Palletsize: ' + (this.item.showWeight ? ('' + this.item.palletSize * this.item.weight + ' Kg') : ('' + this.item.palletSize + ' Uds'))
    }
  },

  watch: {
    'item.productId': {
      handler: function (productId, oldProductId) {
        console.log('productId changed')
        if (productId && this.editMode) {
          // complement new item with product info
          if (productId !== oldProductId) {
            console.log('update weight', productId)
            const product = this.productsSelect.find(item => item.productId === productId)
            this.item.showWeight = product.showWeight
            this.item.weight = product.weight
            this.item.productName = product.productName
            this.item.palletSize = product.palletSize
            this.qty = 0
            // manipulate directly item.price because purchaseListPrice is price per unit and not per kg
            this.$set(this.item, 'price', product.purchaseListPrice)
          }
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }

  },

  methods: {
    // we use @change=changeQty() instead of v-model because v-model triggers changes at writing instead of waiting till leaving input
    changeQty (qty) {
      if (this.item.showWeight) {
        this.qty = Math.ceil(qty / this.item.weight) * this.item.weight
      }
      console.log('qty', this.qty, this.item.qty)
    },
    filterProduct (item, queryText, itemText) {
      // search from 'start'
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) === 0
    }
  }
}
</script>
