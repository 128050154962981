<template>
  <v-container
    fluid
  >
    <v-row
      class="overflowLayout"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          style="white-space: nowrap;"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          item-key="supplierId"
          @update:options="updateQuery()"
          fixed-header
          height="calc(100vh - 130px)"
          mobile-breakpoint="0"
          @click:row="showItem"
          :value="selected"
          single-select
        >
            <template
              v-slot:[`body.prepend`]="{ headers }"
            >
            <tr
              class="tableFilter"
              py-3
            >
              <td v-for="(header,key) in headers" :key="key" class="pb-1">
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear proveedor (Ctrl. + Ins.)"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemsName: 'suppliers',
      itemName: 'supplier',
      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'supplierId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number',
          width: 100
        },

        {
          text: 'Nombre',
          value: 'supplierName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'C.P.',
          value: 'zipCode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Ciudad',
          value: 'city',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'País',
          value: 'isoCountry',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'NIF/RUT/VAT',
          value: 'vatNo',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Compras',
          align: 'right',
          value: 'purchases12m'
        }

      ]
    }
  },
  methods: {

    /*
     * all the magic here is done through several methods from ItemsMixins
     */
    defaultOptions () {
      return {
        sortBy: [this.itemName + 'Id'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    }
  }
}
</script>
