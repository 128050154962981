<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="2"
                sm="2"
              >
                <v-text-field
                  dense
                  disabled
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.supplierId"
                  label="Nº Proveedor"
                ></v-text-field>
              </v-col>
              <v-col
                cols="10"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  ref="focussedInput"
                  type="text"
                  maxlength=100
                  v-model="item.supplierName"
                  :rules="supplierNameRules"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=16
                  v-model="item.vatNo"
                  label="NIF/RUT/VAT"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=45
                  v-model="item.street"
                  label="Calle"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=10
                  v-model="item.zipCode"
                  label="C.P."
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=25
                  v-model="item.city"
                  label="Ciudad"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=18
                  v-model="item.province"
                  label="Provincia"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.isoCountry"
                  :rules="isoCountryRules"
                  label="País *"
                  :items="isocountrySelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.comments"
                  label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-container>
          <!-- footer -->
          <div
            class="text-caption"
          >
            <span v-if="item.supplierId === 'new_'">Pendiente de guardar.</span>
            <span v-else>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="editMode && item.supplierId !== 'new_' && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            title="Volver a lista (Esc)"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemName: 'supplier', // needed for ItemMixins
      itemsName: 'suppliers', // needed for ItemMixins
      isocountrySelect: this.$store.state.isocountrySelect,
      supplierNameRules: [
        (v) => !!v || '¡Se requiere un nombre de proveedor'
      ],
      isoCountryRules: [
        (v) => !!v || '¡Falta elegir el país'
      ]
    }
  },
  methods: {
    createItem () {
      this.item = {
        supplierId: 'new_',
        supplierName: '',
        street: '',
        zipCode: '',
        city: '',
        province: '',
        isoCountry: this.$store.getters.getDefault('isocountry'),
        vatNo: ''
      }
      this.editMode = true
    }
  }
}

</script>
